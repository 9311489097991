import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.js";
import Video from "../../../../components/video.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Effective Rank`}</h1>
    <p>{`Roy `}<em parentName="p">{`et al`}</em><sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{` provides the following definition for the effective rank:`}</p>
    <p>{`Consider a matrix `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`A`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`A`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.68333em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`A`}</span></span></span></span></span>{` with a singular
value decomposition (SVD)`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`A`}</mi><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`U`}</mi><mi parentName="mrow">{`D`}</mi><mi parentName="mrow">{`V`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`A = U D V`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.68333em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`A`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.68333em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.10903em"
                }
              }}>{`U`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.02778em"
                }
              }}>{`D`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.22222em"
                }
              }}>{`V`}</span></span></span></span></span></div>
    <p>{`where D is a `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`M`}</mi><mo parentName="mrow">{`×`}</mo><mi parentName="mrow">{`N`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`M \\times N`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.76666em",
                  "verticalAlign": "-0.08333em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.10903em"
                }
              }}>{`M`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`×`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.68333em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.10903em"
                }
              }}>{`N`}</span></span></span></span></span>{` diagonal matrix with the singular values`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`σ`}</mi><mn parentName="msub">{`1`}</mn></msub><mo parentName="mrow">{`≥`}</mo><msub parentName="mrow"><mi parentName="msub">{`σ`}</mi><mn parentName="msub">{`2`}</mn></msub><mo parentName="mrow">{`≥`}</mo><mo parentName="mrow">{`⋯`}</mo><mo parentName="mrow">{`≥`}</mo><msub parentName="mrow"><mi parentName="msub">{`σ`}</mi><mi parentName="msub">{`Q`}</mi></msub><mo parentName="mrow">{`≥`}</mo><mn parentName="mrow">{`0`}</mn></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\sigma_1 \\geq \\sigma_2 \\geq \\cdots \\geq \\sigma_Q \\geq 0`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.7859700000000001em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`σ`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.30110799999999993em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5500000000000003em",
                            "marginLeft": "-0.03588em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`1`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`≥`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.7859700000000001em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`σ`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.30110799999999993em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5500000000000003em",
                            "marginLeft": "-0.03588em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`2`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`≥`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.7719400000000001em",
                  "verticalAlign": "-0.13597em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}>{`⋯`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`≥`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.922078em",
                  "verticalAlign": "-0.286108em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`σ`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.328331em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5500000000000003em",
                            "marginLeft": "-0.03588em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`Q`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.286108em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`≥`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.64444em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`0`}</span></span></span></span></span></div>
    <p>{`where `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`Q`}</mi><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`min`}</mi><mo parentName="mrow">{`⁡`}</mo><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`{`}</mo><mi parentName="mrow">{`M`}</mi><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mi parentName="mrow">{`N`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`}`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`Q = \\min\\{M, N\\}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8777699999999999em",
                  "verticalAlign": "-0.19444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`Q`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mop"
              }}>{`min`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.10903em"
                }
              }}>{`M`}</span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.10903em"
                }
              }}>{`N`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`}`}</span></span></span></span></span>{`.`}</p>
    <p><strong parentName="p">{`Definition 1 (Nuclear norm)`}</strong>{`  `}<em parentName="p">{`The nuclear norm of the matrix `}<span parentName="em" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`A`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`A`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.68333em",
                    "verticalAlign": "0em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`A`}</span></span></span></span></span>{` is defined as`}</em></p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∥`}</mi><mi parentName="mrow">{`σ`}</mi><msub parentName="mrow"><mi parentName="msub" {...{
                      "mathvariant": "normal"
                    }}>{`∥`}</mi><mn parentName="msub">{`1`}</mn></msub><mo parentName="mrow">{`=`}</mo><munderover parentName="mrow"><mo parentName="munderover">{`∑`}</mo><mrow parentName="munderover"><mi parentName="mrow">{`i`}</mi><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`1`}</mn></mrow><mi parentName="munderover">{`Q`}</mi></munderover><msub parentName="mrow"><mi parentName="msub">{`σ`}</mi><mi parentName="msub">{`i`}</mi></msub><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`.`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\Vert \\sigma \\Vert_1=\\sum_{i=1}^Q \\sigma_i.`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∥`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`σ`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`∥`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.30110799999999993em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5500000000000003em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`1`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "3.1531130000000003em",
                  "verticalAlign": "-1.277669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mop op-limits"
              }}><span parentName="span" {...{
                  "className": "vlist-t vlist-t2"
                }}><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "1.8754440000000003em"
                      }
                    }}><span parentName="span" {...{
                        "style": {
                          "top": "-1.872331em",
                          "marginLeft": "0em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3.05em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "sizing reset-size6 size3 mtight"
                        }}><span parentName="span" {...{
                            "className": "mord mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`i`}</span><span parentName="span" {...{
                              "className": "mrel mtight"
                            }}>{`=`}</span><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`1`}</span></span></span></span><span parentName="span" {...{
                        "style": {
                          "top": "-3.050005em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3.05em"
                          }
                        }}></span><span parentName="span"><span parentName="span" {...{
                            "className": "mop op-symbol large-op"
                          }}>{`∑`}</span></span></span><span parentName="span" {...{
                        "style": {
                          "top": "-4.347113em",
                          "marginLeft": "0em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3.05em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "sizing reset-size6 size3 mtight"
                        }}><span parentName="span" {...{
                            "className": "mord mathnormal mtight"
                          }}>{`Q`}</span></span></span></span><span parentName="span" {...{
                      "className": "vlist-s"
                    }}>{`​`}</span></span><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "1.277669em"
                      }
                    }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`σ`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.31166399999999994em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5500000000000003em",
                            "marginLeft": "-0.03588em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`i`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`.`}</span></span></span></span></span></div>
    <p>{`Then the sequence of singular values gives rise to a nominal distribution`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`p`}</mi><mi parentName="msub">{`i`}</mi></msub><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><msub parentName="mfrac"><mi parentName="msub">{`σ`}</mi><mi parentName="msub">{`i`}</mi></msub><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∥`}</mi><mi parentName="mrow">{`σ`}</mi><msub parentName="mrow"><mi parentName="msub" {...{
                          "mathvariant": "normal"
                        }}>{`∥`}</mi><mn parentName="msub">{`1`}</mn></msub></mrow></mfrac><mtext parentName="mrow">{` for `}</mtext><mi parentName="mrow">{`i`}</mi><mo parentName="mrow">{`∈`}</mo><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`{`}</mo><mn parentName="mrow">{`1`}</mn><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mn parentName="mrow">{`2`}</mn><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mo parentName="mrow">{`…`}</mo><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mi parentName="mrow">{`Q`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`}`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`p_i = \\frac{\\sigma_i}{\\Vert \\sigma \\Vert_1} \\text{ for } i \\in \\{1, 2, \\dots, Q\\}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.19444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`p`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.31166399999999994em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5500000000000003em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`i`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.04356em",
                  "verticalAlign": "-0.936em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.1075599999999999em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`∥`}</span><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.03588em"
                              }
                            }}>{`σ`}</span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}>{`∥`}</span><span parentName="span" {...{
                                "className": "msupsub"
                              }}><span parentName="span" {...{
                                  "className": "vlist-t vlist-t2"
                                }}><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.30110799999999993em"
                                      }
                                    }}><span parentName="span" {...{
                                        "style": {
                                          "top": "-2.5500000000000003em",
                                          "marginLeft": "0em",
                                          "marginRight": "0.05em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "2.7em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "sizing reset-size6 size3 mtight"
                                        }}><span parentName="span" {...{
                                            "className": "mord mtight"
                                          }}>{`1`}</span></span></span></span><span parentName="span" {...{
                                      "className": "vlist-s"
                                    }}>{`​`}</span></span><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.15em"
                                      }
                                    }}><span parentName="span"></span></span></span></span></span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.03588em"
                                }
                              }}>{`σ`}</span><span parentName="span" {...{
                                "className": "msupsub"
                              }}><span parentName="span" {...{
                                  "className": "vlist-t vlist-t2"
                                }}><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.31166399999999994em"
                                      }
                                    }}><span parentName="span" {...{
                                        "style": {
                                          "top": "-2.5500000000000003em",
                                          "marginLeft": "-0.03588em",
                                          "marginRight": "0.05em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "2.7em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "sizing reset-size6 size3 mtight"
                                        }}><span parentName="span" {...{
                                            "className": "mord mathnormal mtight"
                                          }}>{`i`}</span></span></span></span><span parentName="span" {...{
                                      "className": "vlist-s"
                                    }}>{`​`}</span></span><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.15em"
                                      }
                                    }}><span parentName="span"></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.936em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{` for `}</span></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`i`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`∈`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`2`}</span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}>{`…`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`Q`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`}`}</span></span></span></span></span></div>
    <p><strong parentName="p">{`Definition 2 (Effective rank)`}</strong>{`  `}<em parentName="p">{`The effective rank of a matrix `}<span parentName="em" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`A`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`A`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.68333em",
                    "verticalAlign": "0em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`A`}</span></span></span></span></span>{` is defined
as the volume`}</em></p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mrow parentName="mrow"><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`e`}</mi><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`r`}</mi><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`a`}</mi><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`n`}</mi><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`k`}</mi></mrow><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`A`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`exp`}</mi><mo parentName="mrow">{`⁡`}</mo><mrow parentName="mrow"><mi parentName="mrow">{`H`}</mi><mrow parentName="mrow"><mo parentName="mrow" {...{
                        "fence": "true"
                      }}>{`(`}</mo><mfrac parentName="mrow"><msub parentName="mfrac"><mi parentName="msub">{`σ`}</mi><mi parentName="msub">{`i`}</mi></msub><mrow parentName="mfrac"><mi parentName="mrow" {...{
                            "mathvariant": "normal"
                          }}>{`∥`}</mi><mi parentName="mrow">{`σ`}</mi><msub parentName="mrow"><mi parentName="msub" {...{
                              "mathvariant": "normal"
                            }}>{`∥`}</mi><mn parentName="msub">{`1`}</mn></msub></mrow></mfrac><mo parentName="mrow" {...{
                        "fence": "true"
                      }}>{`)`}</mo></mrow></mrow><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`.`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathrm{erank}(A) = \\exp{H\\left(\\frac{\\sigma_i}{\\Vert \\sigma \\Vert_1}\\right)}.`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathrm"
                }}>{`e`}</span><span parentName="span" {...{
                  "className": "mord mathrm"
                }}>{`r`}</span><span parentName="span" {...{
                  "className": "mord mathrm"
                }}>{`a`}</span><span parentName="span" {...{
                  "className": "mord mathrm"
                }}>{`n`}</span><span parentName="span" {...{
                  "className": "mord mathrm"
                }}>{`k`}</span></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`A`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.40003em",
                  "verticalAlign": "-0.95003em"
                }
              }}></span><span parentName="span" {...{
                "className": "mop"
              }}>{`exp`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.08125em"
                  }
                }}>{`H`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.16666666666666666em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "minner"
                }}><span parentName="span" {...{
                    "className": "mopen delimcenter",
                    "style": {
                      "top": "0em"
                    }
                  }}><span parentName="span" {...{
                      "className": "delimsizing size3"
                    }}>{`(`}</span></span><span parentName="span" {...{
                    "className": "mord"
                  }}><span parentName="span" {...{
                      "className": "mopen nulldelimiter"
                    }}></span><span parentName="span" {...{
                      "className": "mfrac"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.1075599999999999em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-2.314em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`∥`}</span><span parentName="span" {...{
                                  "className": "mord mathnormal",
                                  "style": {
                                    "marginRight": "0.03588em"
                                  }
                                }}>{`σ`}</span><span parentName="span" {...{
                                  "className": "mord"
                                }}><span parentName="span" {...{
                                    "className": "mord"
                                  }}>{`∥`}</span><span parentName="span" {...{
                                    "className": "msupsub"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-t vlist-t2"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "0.30110799999999993em"
                                          }
                                        }}><span parentName="span" {...{
                                            "style": {
                                              "top": "-2.5500000000000003em",
                                              "marginLeft": "0em",
                                              "marginRight": "0.05em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "2.7em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "sizing reset-size6 size3 mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`1`}</span></span></span></span><span parentName="span" {...{
                                          "className": "vlist-s"
                                        }}>{`​`}</span></span><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "0.15em"
                                          }
                                        }}><span parentName="span"></span></span></span></span></span></span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-3.23em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "frac-line",
                                "style": {
                                  "borderBottomWidth": "0.04em"
                                }
                              }}></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-3.677em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}><span parentName="span" {...{
                                    "className": "mord mathnormal",
                                    "style": {
                                      "marginRight": "0.03588em"
                                    }
                                  }}>{`σ`}</span><span parentName="span" {...{
                                    "className": "msupsub"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-t vlist-t2"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "0.31166399999999994em"
                                          }
                                        }}><span parentName="span" {...{
                                            "style": {
                                              "top": "-2.5500000000000003em",
                                              "marginLeft": "-0.03588em",
                                              "marginRight": "0.05em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "2.7em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "sizing reset-size6 size3 mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mathnormal mtight"
                                              }}>{`i`}</span></span></span></span><span parentName="span" {...{
                                          "className": "vlist-s"
                                        }}>{`​`}</span></span><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "0.15em"
                                          }
                                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.936em"
                            }
                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                      "className": "mclose nulldelimiter"
                    }}></span></span><span parentName="span" {...{
                    "className": "mclose delimcenter",
                    "style": {
                      "top": "0em"
                    }
                  }}><span parentName="span" {...{
                      "className": "delimsizing size3"
                    }}>{`)`}</span></span></span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`.`}</span></span></span></span></span></div>
    <p><em parentName="p">{`where `}<span parentName="em" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`H`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.68333em",
                    "verticalAlign": "0em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.08125em"
                  }
                }}>{`H`}</span></span></span></span></span>{` is the Shannon entropy of the nominal distribution given by the matrix spectrum.`}</em></p>
    <h1>{`Entropy of A Continuous Spectrum`}</h1>
    <p>{`The effective rank given by Roy `}<em parentName="p">{`et al`}</em>{` is only defined on discrete spectrum. We know that
computing the entropy of a continuous distribution require special care`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{`.`}</p>
    <h1>{`Spectral Entropy in Finite Sampling Window`}</h1>
    <p>{`An additional consideration when computing the spectral entropy on a discrete spectrum, is that
the windowing function produced by applying discrete Fourier transform in a finite message
window imprints a spectral floor that contributes to the spectral distribution. We can subtract
the spectral entropy of this windowing function from the spectral entropy of the discrete Fourier
spectrum, `}<em parentName="p">{`s.t.`}</em>{` the entropy of the discrete spectrum is `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mn parentName="mrow">{`0`}</mn></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`0`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.64444em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`0`}</span></span></span></span></span>{` when there is just a single component.`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`S`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`H`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`S`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`−`}</mo><mi parentName="mrow">{`H`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`w`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H(S) = H(S) - H(w)`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.05764em"
                }
              }}>{`S`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.05764em"
                }
              }}>{`S`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`−`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.02691em"
                }
              }}>{`w`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span></div>
    <p><strong parentName="p">{`Acknowledgements:`}</strong></p>
    <p>{`Leave the Acknowledgements here.`}</p>
    <h1>{`References`}</h1>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`Roy, O. and Vetterli, M. (2007) ‘The effective rank: A measure of effective dimensionality’, in 2007 15th European Signal Processing Conference, pp. 606–610. Available at: `}<a parentName="li" {...{
            "href": "http://ieeexplore.ieee.org/document/7098875/"
          }}>{`http://ieeexplore.ieee.org/document/7098875/`}</a><a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`Ge Yang, 'Differential Entropy and Invariant Entropy Measure', Available at: `}<a parentName="li" {...{
            "href": "https://www.episodeyang.com/blog/2022/10-23/entropy_continuous/"
          }}>{`https://www.episodeyang.com/blog/2022/10-23/entropy_continuous/`}</a><a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      